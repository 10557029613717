.video-box-left-text {
  font-size: 75px;
  position: absolute;
  left: 20px;
  width: 45%;
  line-height: 100px;
  color: white;
  text-align: start;
  top: -60px;

  @media screen and (max-width: 2000px) {
    font-size: 50px;
    bottom: 10px;
  }

  @media screen and (max-width: 1250px) {
    font-size: 40px;
    top: unset;
  }

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    text-align: start;
    width: 100%;
    margin-bottom: 40px !important;
    position: unset;
    line-height: 30px;
    padding: 10px;
    top: unset;
  }
}

.read-more-button-n {
  cursor: pointer;
  border: 1px solid white;
  text-align: center;
  width: 220px;
  padding: 13px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
}

.read-more-parent-n {
  position: absolute;
  right: 50px;

  @media screen and (max-width: 1000px) {
    position: unset;
    padding: 10px;
  }
}
