/*----------------------------------------*/
/*  12. CONTACT CSS START
/*----------------------------------------*/

.contact {
  &__area {
  }
  &__info {
    & h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 52px;
    }
    & p {
      margin-bottom: 55px;
    }
    & ul {
      & li {
        &:hover {
          & .contact__info-icon {
            & i {
              border-color: $black-2;
              background: $black-2;
              color: $white;
            }
          }
        }
      }
    }
    &-icon {
      & i {
        font-size: 18px;
        color: #6c6c6c;
        display: inline-block;
        width: 53px;
        height: 53px;
        line-height: 49px;
        text-align: center;
        border: 2px solid $border;
      }
    }
    &-content {
      & h6 {
        font-size: 14px;
        margin-bottom: 2px;
      }
      & span {
        color: $black-soft-2;
      }
    }
  }
  &__social {
    & ul {
      & li {
        display: inline-block;
        margin-right: 42px;
        & a {
          font-size: 18px;
          color: $black-soft-2;
          &:hover {
            color: $theme-color;
          }
        }
      }
    }
  }
  &__form {
    @media #{$md} {
      margin-top: 50px;
    }
    @media #{$sm} {
      margin-top: 50px;
    }
    @media #{$xs} {
      margin-top: 50px;
    }
    & h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 45px;
    }
  }
  &__input {
    & label {
      color: #333333;
      display: inline-block;
      margin-bottom: 10px;
      & span.required {
        color: red;
      }
    }

    & input,
    & textarea {
      height: 50px;
      width: 100%;
      border: 1px solid #ececec;
      padding: 0 15px;
      font-size: 14px;
      // margin-bottom: 22px;
      &:focus {
        border-color: $theme-color;
      }
    }
    & textarea {
      height: 180px;
      resize: none;
      margin-bottom: 45px;
    }
  }
  &__map {
    &-wrapper {
      height: 590px;
      & iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}
.contact__input {
  margin-bottom: 22px;
}

.sky-parent {
  padding: 300px;

  @media screen and (max-width: 1000px) {
    padding: 50px;
  }
}

.submit-button {
  background-color: #0d6782;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 35px;
  padding-left: 35px;
  text-transform: uppercase;
  font-size: 30px;
  color: white;
  right: 0px !important;
}

.input-container_contact {
  position: relative;
}

.adornment_contact {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}

.adornment_contact_area {
  position: absolute;
  left: 10px;
  top: 23px;
  transform: translateY(-50%);
  color: #555;
}

.input_contact {
  padding-left: 30px !important;
  border: 0px solid transparent !important;
}

.about-images-wrapper {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }

  .about-image {
    height: 700px;
    width: 50%;
    object-fit: cover;

    @media screen and (max-width: 460px) {
      width: 100%;
      max-height: 200px;
    }

    @media screen and (max-width: 740px) {
      width: 100%;
      max-height: 450px;
    }

    @media screen and (max-width: 980px) {
      height: 350px;
    }

    @media screen and (max-width: 1200px) {
      height: 350px;
    }

    @media screen and (max-width: 1500px) {
      height: 450px;
    }

    @media screen and (max-width: 1700px) {
      height: 550px;
    }
  }
}

.settings-text {
  max-width: 80vw;
}

.about-us-text-lower {
  @media screen and (min-width: 1200px) {
    height: 700px !important;
  }
}

.about-us-parent_ {
  @media screen and (min-width: 1200px) {
    max-width: 80vw;
  }

  @media screen and (min-width: 1500px) {
    max-width: 70vw;
  }
}

.about-text_ {
  font-size: 20px !important;

  @media screen and (min-width: 1400px) {
    padding: 20px !important;
  }

  @media screen and (min-width: 1400px) {
    font-size: 25px !important;
  }
}
