// Font Family
$pop: "Poppins", sans-serif;

// Colors ---------------
$white: #ffffff;

$black: #201f1f;
$black-2: #323232;
$black-3: #222222;

$bolt-orange: #ff5d17;
$bolt-darkerOrange: #ff4e05;
$bolt-orangeRed: #ff3805;
$bolt-arrow: #a4a4a4;
$bolt-white: #ffffff;

$footer-bg: #151616;

$grey: #f5f5f5;
$grey-2: #e1e1e1;
$grey-3: #9d9d9d;
$grey-4: #6666;

$theme-color: #d5ac4c;
$theme-2-color: #d5ac4c;
$body-text-color: #848b8a;
// $theme-color: #bc8246;
// $theme-2-color: #8a8f6a;
// $body-text-color: #848b8a;

//border colors
$border: #ebebeb;
$border-2: #383838;

// Heading Color
$heading-color: #201f1f;

// Soft color
$black-soft: #444;
$black-soft-2: #606060;
$black-soft-3: #757575;

// Responsive Variables
$laptop: "only screen and (min-width: 1200px) and (max-width: 1600px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm: "only screen and (min-width: 576px) and (max-width: 767px)";
$xs: "(max-width: 575px)";
$large: "(min-width: 1200px)";
