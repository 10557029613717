/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.hoveredImage {
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
}

.hoveredImage:hover {
  border: 1px solid #d5ac4c;
}

@media only screen and (max-width: 767px) {
  .navigation-container {
    display: none;
  }
}
