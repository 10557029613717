/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: $pop;
  font-size: 14px;
  font-weight: normal;
  color: $body-text-color;
  line-height: 24px;
  overflow-x: hidden;
}

.w-img {
  & img {
    width: 100%;
  }
}
.m-img {
  & img {
    max-width: 100%;
  }
}
.cursor-pointer {
  cursor: pointer;
}
a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include transition(0.3s);
}
a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
a,
button {
  color: inherit;
  outline: none;
  border: none;
}
button:hover {
  cursor: pointer;
}
button:focus {
  outline: 0;
  border: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $pop;
  color: $heading-color;
  margin-top: 0px;
  font-weight: 500;
  line-height: 1.2;
  @include transition(0.3s);
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 27px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-family: $pop;
  font-size: 14px;
  font-weight: normal;
  color: $body-text-color;
  margin-bottom: 15px;
  line-height: 24px;
}

*::-moz-selection {
  background: $theme-color;
  color: $white;
  text-shadow: none;
}
::-moz-selection {
  background: $theme-color;
  color: $white;
  text-shadow: none;
}
::selection {
  background: $theme-color;
  color: $white;
  text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
  color: $black;
  font-size: 14px;
  opacity: 1;
}
*::placeholder {
  color: $black;
  font-size: 14px;
  opacity: 1;
}

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}
.clear {
  clear: both;
}

.f-left {
  float: left;
}
.f-right {
  float: right;
}

.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
  background: $grey;
}

.theme-bg {
  background: $theme-color;
}
.white-bg {
  background: $white;
}
.black-bg {
  background: $black;
}

.footer-bg {
  background: $footer-bg;
}

.dark-soft-bg {
  background: #282828;
}

/*--
    - color
-----------------------------------------*/

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: $white !important;
}

.white-color {
  color: $white;
}

.theme-color {
  color: $theme-color !important;
}
.black-color {
  color: $black;
}
// .primary-color {
// 	color: #222;
// }

// others common css here :)

/* spacing */

.mb-265 {
  margin-bottom: 265px;
}

.mt--95 {
  margin-top: -95px;
}

/* padding */

.pl-250 {
  padding-left: 250px;
  @media #{$laptop} {
    padding-left: 50px;
  }
  @media #{$lg} {
    padding-left: 25px;
  }
  @media #{$md} {
    padding-left: 25px;
  }
  @media #{$sm} {
    padding-left: 25px;
  }
  @media #{$xs} {
    padding-left: 10px;
  }
}

/* box spacing */

.box-25 {
  padding-left: 25px;
  padding-right: 25px;
  @media #{$sm} {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media #{$xs} {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.box-m-15 {
  margin-left: 15px;
  margin-right: 15px;
  @media #{$sm} {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media #{$xs} {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.box-white {
  @media #{$xs} {
    padding-top: 0;
  }
}

.box-white-inner {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    top: -50px;
    left: -50px;
    width: calc(100% + 100px);
    height: calc(100% + 30px);
    background: #ffffff;
    @media #{$laptop} {
      top: -15px;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
    }
    @media #{$lg} {
      top: -30px;
      left: -30px;
      width: calc(100% + 60px);
      height: calc(100% + 60px);
    }
    @media #{$md} {
      top: -40px;
      left: -35px;
      width: calc(100% + 70px);
      height: calc(100% + 80px);
    }
    @media #{$sm} {
      top: -20px;
      left: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
    }
    @media #{$xs} {
      top: -15px;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 30px);
    }
  }
}

.box-pb-40 {
  padding-bottom: 40px;
  @media #{$sm} {
    padding-bottom: 30px;
  }
  @media #{$xs} {
    padding-bottom: 15px;
  }
}

/* custom container*/
.custom-container {
  padding-left: 0;
  padding-right: 0;
}
.custom-container-2 {
  @media (min-width: 1400px) {
    max-width: 1220px;
  }
}
/* btn */

.os-btn {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  height: 50px;
  line-height: 46px;
  padding: 0 42px;
  border: 2px solid $border;
  color: $black-2;
  background: transparent;
  z-index: 1;
  font-size: 12px;
  & span {
    color: $theme-color;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #e9c85e;
    z-index: -1;
  }
  &:hover {
    color: $white;
    border-color: #e9c85e;
    &::after {
      height: 100%;
    }
    & span {
      color: $white;
    }
  }
  &-2 {
    border-color: $black-2;
  }
  &-3 {
    padding: 0 77px;
  }
  &-4 {
    &::after {
      background: #e9c85e;
    }
  }
  &-black {
    color: $white;
    background-color: $black-2;
    border-color: $black-2;
    &::after {
      background-color: $theme-color;
    }
    &:hover {
      border-color: $theme-color;
    }
  }
  &-white {
    color: $white;
    border-color: $white;
    &:hover {
      color: $black-2;
      border-color: $white;
      &::after {
        background: $white;
      }
    }
  }
}
.os-btn-4 {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  height: 50px;
  line-height: 46px;
  padding: 0 42px;
  border: 2px solid $black-2;
  color: $black-2;
  background: transparent;
  z-index: 1;
  font-size: 12px;
  & span {
    color: $theme-color;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $theme-2-color;
    z-index: -1;
  }
  &:hover {
    color: $white;
    border-color: $theme-2-color;
    &::after {
      height: 100%;
    }
    & span {
      color: $white;
    }
  }
}
.os-btn-5 {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  height: 50px;
  line-height: 46px;
  padding: 0 42px;
  border: 2px solid $border;
  color: $white;
  background: transparent;
  z-index: 1;
  font-size: 12px;
  & span {
    color: $theme-color;
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $theme-2-color;
    z-index: -1;
  }
  &:hover {
    color: $white;
    border-color: $theme-2-color;
    &::after {
      height: 100%;
    }
    & span {
      color: $white;
    }
  }
}
.link-btn {
  font-size: 12px;
  color: $black-soft;
  text-transform: capitalize;
  font-weight: 500;
  &:hover {
    color: $theme-color;
  }
}
.action-btn {
  background: $black;
  color: $white;
  font-size: 14px;
  cursor: pointer;
  padding: 15px 31.9px;
  transition: 0.3s;
  &:hover {
    background: $theme-2-color;
    color: $white;
  }
}

/* section title */
.section {
  &__title {
    position: relative;
    &::after {
      border-top: 1px solid $border;
      content: "";
      left: 0;
      margin: 0;
      position: absolute;
      top: 50%;
      width: 100%;
    }
    & h2 {
      display: inline-block;
      font-weight: 400;
      position: relative;
      background: #fff none repeat scroll 0 0;
      padding: 0 50px;
      z-index: 1;
      @media #{$xs} {
        font-size: 30px;
        padding: 0 15px;
      }
    }
    & p {
      color: $black-soft-2;
      margin-bottom: 0;
    }
    &-2 {
      padding-left: 130px;
      padding-right: 130px;
      font-size: 24px;
      text-transform: uppercase;
      color: $black;
      font-weight: 700;
      @media #{$sm,$xs} {
        font-size: 20px;
      }
      @media #{$xs} {
        padding-right: 0;
        padding-left: 0;
      }
      & span {
        padding: 0 70px;
        display: inline-block;
        position: relative;
        @media #{$sm,$xs} {
          padding: 0 30px;
        }
        &::before,
        &::after {
          width: 130px;
          height: 4px;
          background: transparent;
          position: absolute;
          content: "";
          top: 13px;
          border-top: 1px solid #ebebeb;
          border-bottom: 1px solid #ebebeb;
          @media #{$xs} {
            display: none;
          }
        }
        &::before {
          right: 100%;
        }
        &::after {
          left: 100%;
        }
      }
    }
  }
  &__wrapper {
    & .sub-title {
      margin: 0 auto 10px;
      max-width: 600px;
    }
  }
}

/* page title area */

.page {
  &__title {
    height: 530px;
    @include background();
    @media #{$sm} {
      height: 400px;
    }
    @media #{$xs} {
      height: 350px;
    }
    &-inner {
      & h1 {
        font-size: 36px;
        color: $black-2;
        text-transform: capitalize;
        margin-bottom: 25px;
      }
      @media #{$sm} {
        margin-top: 100px;
      }
      @media #{$xs} {
        margin-top: 100px;
      }
    }
    &-breadcrumb {
      & .breadcrumb {
        background: transparent;
        padding: 0;
        margin-bottom: 0;
        @include border-radius(0);
      }
      & .breadcrumb-item {
        font-size: 12px;
        text-transform: capitalize;
        color: $black-soft;
        font-weight: 500;
        & a {
          &:hover {
            color: $theme-color;
          }
        }
        &.active {
          color: $black-2;
          &::before {
            color: $black-soft;
          }
        }
      }
    }
  }
}

/* rating */

.rating {
  & ul {
    & li {
      display: inline-block;
      & span {
        font-size: 10px;
        color: $theme-color;
      }
    }
  }
  &.rating-shop {
    & ul {
      display: inline-block;
      & li {
        & span {
          font-size: 12px;
          color: $theme-color;
        }
      }
    }
    & span {
      font-size: 12px;
      font-weight: 500;
    }
    &-2 {
      & ul {
        & li {
          & span {
            color: $theme-2-color;
          }
        }
      }
    }
  }
}

.rating-left {
  position: relative;
  padding-left: 25px;
  margin-left: 25px;
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    @include transform(translateY(-50%));
    height: 12px;
    width: 1px;
    background: #dcdcdc;
  }
  & a {
    &:hover {
      color: $theme-color;
    }
  }
}

/* scroll to top */
#scroll a {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  color: $white;
  list-style-type: none;
  background: $theme-color;
  margin-right: 20px;
  border: 1px solid $theme-color;
  z-index: 999;
}
#scroll .scroll-link {
  border: 1px solid $theme-2-color;
  background: $theme-2-color;
}

/* border bottom 1*/

.border-top-1 {
  border-top: 1px solid $border;
}

/* sidebar */

.sidebar {
  &__wrapper {
    @media #{$md} {
      margin-top: 50px;
    }
    @media #{$sm} {
      margin-top: 50px;
    }
    @media #{$xs} {
      margin-top: 50px;
    }
  }
  &__widget {
  }
  &__widget-title {
    & h3 {
      font-size: 20px;
      margin-bottom: 0;
    }
  }
  &__links {
    & ul {
      & li {
        margin-bottom: 8px;
        & a {
          color: $black-soft;
          &:hover {
            color: $black-2;
          }
        }
      }
    }
  }
}

/* basic page navigation */

.basic-pagination {
  & ul {
    display: block;
    & li {
      display: inline-block;
      margin: 0 5px;
      &.active {
        & a {
          border-color: $black;
        }
      }
      & a {
        height: 35px;
        width: 35px;
        background: transparent;
        color: $black;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin: 0px;
        display: inline-block;
        text-align: center;
        border: 2px solid $border;
        &:hover {
          border-color: $black;
        }
      }
    }
  }
}
.page-item.active .page-link {
  z-index: 3;
  color: #201f1f;
  background-color: inherit;
  border-color: inherit;
}
.page-link:focus {
  box-shadow: none;
}
//hover effect

.effectThree a {
  display: block;
  overflow: hidden;
  position: relative;
}
.effectThree a img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.35s ease 0s;
  -o-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
}
.effectThree a:before,
.effectThree a:after {
  content: "";
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  -webkit-transition: all 0.35s ease 0s;
  -o-transition: all 0.35s ease 0s;
  transition: all 0.35s ease 0s;
  z-index: 1;
}
.effectThree a:before {
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  bottom: 20px;
  left: 10px;
  right: 10px;
  top: 20px;
  -webkit-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  transform: scale(1, 0);
}
.effectThree a:after {
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  left: 20px;
  right: 20px;
  bottom: 10px;
  top: 10px;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  transform: scale(0, 1);
}
.effectThree a:hover:before,
.effectThree a:hover:after {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.effectThree a:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

@media #{$large} {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
