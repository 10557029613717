.s_show_parent {
  .read_more_button {
    background-color: $bolt-orangeRed;
    color: white;
    padding: 13px;
    font-weight: bold;
    border-radius: 10px;
    font-size: medium;
  }
}

.categories_carousel {
  .category_item {
    height: 22vw;
    width: 50vw;

    box-shadow: 2px 4px 5px 1px rgba(115, 115, 115, 0.3);
    -moz-box-shadow: 2px 4px 10px 1px rgba(115, 115, 115, 1);

    @media screen and (max-width: 500px) {
      height: 100vw;
      width: 100vw;
    }

    @media screen and (min-width: 1500px) {
      height: 25vw;
      width: 50vw;
    }
  }
}
