.big_product_title {
  position: absolute;
  bottom: 10px;

  @media screen and (min-width: 2000px) {
    bottom: 120px;
  }

  @media screen and (min-width: 2300px) {
    position: unset;
  }
}
