.about-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  margin-top: 5vh;
}

.about-us-content {
  display: flex;
  align-items: start;
}

.image {
  margin-right: 20px;
}

.image img {
  max-width: 100%;
  height: auto;
}

.description {
  text-align: left;
  /* border: 1px solid goldenrod; */
  /* padding: 50px; */
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.read-more-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.read-more-button:hover {
  background-color: #0056b3;
}

/* Media Query for smaller devices */
@media (max-width: 768px) {
  .about-us-content {
    flex-direction: column;
    text-align: center;
  }

  .image {
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .description {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: silver;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
