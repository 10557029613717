/*----------------------------------------*/
/*  04. BANNER CSS START
/*----------------------------------------*/

.banner {
  &__area {
    &-df {
      display: flex;
      @media #{$lg,$md,$sm,$xs} {
        display: block;
      }
    }
  }
  &__inner {
    &::before {
      position: absolute;
      content: "";
      top: -20px;
      left: -20px;
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      background: $white;
      @media #{$laptop} {
        width: calc(100% + 30px);
        height: calc(100% + 30px);
        top: -15px;
        left: -15px;
      }
      @media #{$xs} {
        display: none;
      }
      @media #{$sm} {
        display: none;
      }
    }
    @media #{$md} {
      margin-top: 30px;
    }
    @media #{$sm} {
      margin-top: 30px;
    }
    @media #{$xs} {
      margin-top: 30px;
    }
  }
  &__thumb {
    & a {
      display: block;
    }
  }
  &__item {
    &:hover {
      & .banner__thumb {
        & img {
          @include transform(scale(1.1));
        }
      }
      & .banner__content {
        top: 60%;
      }
    }
    &-2 {
      padding-left: 0;
      padding-right: 0;

      &:hover {
        & .banner__thumb {
          & img {
            @include transform(scale(1.1));
          }
        }
      }
    }
    &-3 {
      position: relative;
      margin-right: 10px;
      width: 50%;
      @media #{$lg,$md,$sm,$xs} {
        width: 100%;
      }
      &:last-child {
        margin-right: 0;
      }
      &-image {
        position: relative;
        &::before {
          position: absolute;
          background: #00000091;
          content: "";
          left: 0;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
          visibility: hidden;
          opacity: 0;
          transition: 0.3s;
        }
        & img {
          width: 100%;
          @media #{$xs,$sm} {
            height: 230px;
          }
        }
      }
      & {
        & .banner__item-3-image::before {
          visibility: visible;
          opacity: 1;
        }
        & .banner__content-5 {
          top: 50%;
          transform: translateY(-50%);
          visibility: visible;
          opacity: 1;

          .blur-effect {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
            opacity: 0;
            transition: opacity 0.3s ease; /* Smooth transition for the blur effect */
          }

          &:hover {
            .blur-effect {
              opacity: 0.2;
            }
          }
        }
      }
    }
  }
  &__content {
    top: 50%;
    left: 10%;
    @include transform(translateY(-50%));
    & span {
      color: $theme-color;
      margin-bottom: 15px;
    }
    & h5 {
      margin-bottom: 17px;
      line-height: 22px;
      & a {
        &:hover {
          color: $theme-color;
        }
      }
    }
    &-2 {
      top: 50%;
      left: 85px;
      @include transform(translateY(-50%));
      @media #{$lg} {
        left: 40px;
      }
      @media #{$md} {
        left: 40px;
      }
      @media #{$sm} {
        left: 40px;
      }
      @media #{$xs} {
        left: 15px;
      }
      &.banner__content-2-right {
        left: 100px;
        @media #{$lg} {
          left: 55px;
        }
        @media #{$md} {
          left: 40px;
        }
        @media #{$sm} {
          left: 40px;
        }
        @media #{$xs} {
          left: 15px;
        }
      }
      & span {
        color: $theme-color;
      }
      & h4 {
        font-size: 18px;
        margin-bottom: 10px;
        margin-bottom: 15px;
        @media #{$laptop} {
          margin-bottom: 25px;
        }
        @media #{$sm} {
          margin-bottom: 25px;
        }
        @media #{$xs} {
          margin-bottom: 25px;
        }
        & a {
          &:hover {
            color: $theme-color;
          }
        }
      }
      & p {
        color: $black-soft;
        margin-bottom: 37px;
        @media #{$laptop} {
          display: none;
        }
        @media #{$lg} {
          display: none;
        }
        @media #{$md} {
          display: none;
        }
        @media #{$sm} {
          display: none;
        }
        @media #{$xs} {
          display: none;
        }
      }
    }
    &-4 {
      left: 25px;
      &.banner__content-4-right {
        left: 45px;
        @media #{$xs} {
          left: 25px;
        }
      }
    }
    &-5 {
      position: absolute;
      top: 40%;
      left: 0;
      right: 0;
      text-align: center;
      transform: translateY(-40%);
      max-width: 450px;
      margin: 0 auto;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;
      & h5 {
        font-size: 18px;
        color: $white;
        margin-bottom: 20px;
      }
      & p {
        color: $white;
        margin-bottom: 30px;
        padding: 0px 10px;
      }
    }
  }
}
.banner-right {
  padding-right: 15px;
  @media #{$md} {
    padding-right: 0;
  }
  @media #{$sm} {
    padding-right: 0;
  }
  @media #{$xs} {
    padding-right: 0;
  }
}
.banner-left {
  padding-left: 15px;
  @media #{$md} {
    padding-left: 0;
  }
  @media #{$sm} {
    padding-left: 0;
  }
  @media #{$xs} {
    padding-left: 0;
  }
}
//video css

.video {
  &__area {
    &-df {
      @include background();
      min-height: 565px;
      position: relative;
      z-index: 1;
      &::before {
        position: absolute;
        content: "";
        background: #00000075;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: -1;
      }
    }
  }
  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    & p {
      font-size: 18px;
      color: $white;
      margin-bottom: 0;
    }
    @media #{$xs,$sm} {
      padding: 0 30px;
    }
  }
  &__button {
    & button {
      display: inline-block;
      height: 60px;
      width: 60px;
      line-height: 56px;
      border: 3px solid $white;
      border-radius: 50%;
      color: $white;
      font-size: 15px;
      background: transparent;
      &:hover {
        border-color: $theme-2-color;
        color: $theme-2-color;
      }
    }
  }
  &__title {
    color: $white;
    font-size: 40px;
    text-transform: uppercase;
    line-height: 48px;
    @media #{$xs,$sm} {
      font-size: 30px;
    }
  }
}

// testimonail css
.testimonial {
  &__area {
    .owl-carousel .owl-item img {
      display: block;
      width: auto;
    }
    .slick-dots {
      text-align: center;
    }
    .slick-dots li {
      display: inline-block;
      vertical-align: middle;
    }
    .slick-dots li > button {
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 5px;
      margin: 3px;
      border: 0;
      background: #e0e0e0;
      font-size: 0;
    }
    .slick-dots li.slick-active > button {
      background-color: $bolt-darkerOrange;
      width: 30px;
    }
  }
  &__image {
    display: flex;
    justify-content: center;
    & img {
      border-radius: 50%;
    }
  }
  &__content {
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
    & P {
      margin-bottom: 30px;
    }
  }
}

//brand css
.brand {
  &__slider {
    &-active {
      .slick-arrow {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s;
      }
      &:hover {
        .slick-arrow {
          visibility: visible;
          opacity: 1;
        }
      }
      .slick-carousel {
        position: relative;
      }
      .slick-carousel .slick-arrow {
        cursor: not-allowed;
        opacity: 0.5;
        filter: alpha(opacity=50);
      }
      .slick-carousel:hover .slick-arrow {
        opacity: 1;
        filter: alpha(opacity=100);
        visibility: visible;
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        margin: -25px auto 0;
        cursor: pointer;
        width: 50px;
        height: 50px;
        z-index: 1;
        padding: 0;
        text-align: center;
        line-height: 48px;
        border-radius: 50%;
        border: 1px solid var(--vela-color-primary);
        color: #fff;
        background: $theme-2-color;
        font-size: 0;
        -webkit-transition: all 0.35s ease;
        -o-transition: all 0.35s ease;
        transition: all 0.35s ease;
        &:hover {
          background: $black-2;
          color: $white;
          border-color: $white;
        }
      }
      .slick-arrow:before {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        font-family: var(--vela-font-icon);
      }
      .slick-arrow.slick-prev {
        left: -15px;
        @media #{$xs} {
          left: 0;
        }
      }
      .slick-arrow.slick-prev:before {
        content: "\f104";
        margin-right: 2px;
        font-family: "Font Awesome 5 pro";
      }
      .slick-arrow.slick-next {
        right: -15px;
        @media #{$xs} {
          right: 0;
        }
      }
      .slick-arrow.slick-next:before {
        content: "\f105";
        margin-left: 3px;
        font-family: "Font Awesome 5 pro";
      }
    }
  }
  &__image {
    &:hover {
      & img {
        opacity: 0.3;
      }
    }
    & img {
      transition: all 0.3s ease-in;
    }
  }
}

.banner-bg-v2 {
  background-size: contain !important;
  background-repeat: no-repeat !important;

  @media screen and (max-width: 1700px) {
    background-size: cover !important;
  }
}

.page__title_v2 {
  height: 350px !important;

  @media screen and (max-width: 1500px) {
    height: 400px !important;
  }

  @media screen and (max-width: 768px) {
    margin-top: -20px !important;
  }
}

.margin-fix-banner {
  margin-left: -15px !important;
  margin-right: -15px !important;

  @media screen and (max-width: 1500px) {
    margin-left: -10% !important;
    margin-right: 0px;
  }
}
