/* Default font size for smaller screens */
.product__price {
  font-size: 12px;
}

/* Adjust font size for larger screens */
@media screen and (min-width: 768px) {
  .product__price {
    font-size: 12px;
  }
}

/* Adjust font size for even larger screens */
@media screen and (min-width: 1024px) {
  .product__price {
    font-size: 16px;
  }
}

/* Add more media queries as needed for different screen sizes */
